@import "../../sass/_variables.scss";
@import "../../sass/_mixin.scss";


.trackingPage {
  background-color: $white-bg;
  padding: 5rem 20px;
}

.form {
  text-align: center;
  margin: auto;

  input {
    // height: 50px;
    border: 1px solid $orange;
    padding: 18px 15px;
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }

  button {
    height: 30px;
    border: 1px solid $orange;
    padding: 5px 18px;
    font-size: 1rem;
    background-color: $orange;
    color: $white-bg;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      background-color: $blue;
      color: white;
    }
  }

  @media only screen and (max-width: 868px) {
    input {
      width: 100%;
    }

    button {
      width: 100%;
      margin: auto;
      text-align: center;
    }
  }
}

.tracking-list {
  padding: 4rem 2rem;
  border: 1px solid bisque;
  box-shadow: 5px 6px 30px 0px rgba(0, 0, 0, 0.1);
  max-width: 700px;
  margin: 4rem auto;

  .id__edit {
    margin-bottom: 2rem;
  }

  .head {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  .tracking {
    text-decoration: none;
    display: flex;
    justify-content: space-between;

    p {
      font-size: 1.6rem;
      margin-bottom: 2rem;
      list-style-type: disc;
      border-bottom: 1px solid bisque;
      padding-bottom: 1rem;
      cursor: pointer;
      color: blue;
    }

    .delete-icon {
      color: $orange;
      cursor: pointer;
      font-size: 1.8rem;
    }
  }
}

.delete_last {
  margin-top: 4rem;
  text-align: right;
  font-size: 1.6rem;
  color: $blue;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
}

.back_link {
  font-size: 1.4rem;
}

.extraForm {
  display: flex;

  @media only screen and (max-width: 868px) {
    flex-direction: column;
  }
}

table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-top: 1rem;

  td,
  th {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 1rem;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tr:hover {
    background-color: #ddd;
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: $orange;
    color: white;
  }
}

.extra_text {
  font-size: 1.4rem;
  margin: 1rem 0;

  p {
    margin-bottom: 1rem;
  }
}

p {
  font-size: 14px;
  margin-bottom: 1rem;
}