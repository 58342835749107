.mainHero {
  width: 100%;
  height: 90vh !important;
}

.swiper {
  width: 100%;
  height: 100%;

  .singleSlide {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 10rem;
    color: white;

    @media only screen and (max-width: 768px) {
      padding-left: 2rem;
    }

    .heading {
      font-size: 30px;
    }

    .main_heading {
      font-size: 50px;
      font-weight: 700;
      text-transform: uppercase;
    }

    .sub_heading {
      font-size: 20px;
      font-weight: 4000;
    }
  }
}

.slider-thumbnails-main__item {
  display: flex;

  // on mobile
  @media only screen and (max-width: 768px) {
    display: block;
  }
}

.slider-thumbnails-main__info {
  background-color: black;
}

.paddingTop {
  padding-top: 10rem;
}