@import '../../sass/_variables.scss';
@import '../../sass/_mixin.scss';


.padding {
  @media only screen and (max-width: 500px) {
    padding: 2rem .5rem;
  }
}


.trackingPage {
  background-color: $white-bg;
  // margin: 5rem auto;
  padding: 5rem 20px;

  .heading {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 2.4rem;
    font-weight: bold;
  }

  .view-message {
    color: blue;
    text-align: center;
    font-weight: bold;
    text-decoration: underline;
    margin-top: 1rem;

    p {
      color: black;
      text-decoration: underline;
    }
  }
}

.form {
  text-align: center;
  margin: auto;

  input {
    height: 30px;
    border: 1px solid $orange;
    padding: 18px 20px;
    font-size: 1rem;
    margin-bottom: 1rem;
    font: inherit;
  }

  button {
    height: 50px;
    border: 1px solid $orange;
    padding: 5px 18px;
    font-size: 1.2rem;
    background-color: $orange;
    color: $white-bg;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      background-color: $blue;
      color: white;
    }
  }

  @media only screen and (max-width: 868px) {
    input {
      width: 100%;
    }

    button {
      width: 100%;
      margin: auto;
      text-align: center;
    }
  }
}

.tracking-list {
  padding: 2rem 1.5rem;
  border: 1px solid bisque;
  box-shadow: 5px 6px 30px 0px rgba(0, 0, 0, 0.1);
  max-width: 700px;
  margin: 4rem auto;

  .head {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  .track__details__info {
    font-weight: 400;
  }

  .tracking {
    text-decoration: none;
    display: flex;
    justify-content: space-between;

    p {
      font-size: 1rem;
      margin-bottom: 2rem;
      list-style-type: disc;
      border-bottom: 1px solid bisque;
      padding-bottom: 1rem;
      cursor: pointer;
      color: black;
      font-weight: bold;
    }

    .delete-icon {
      color: $orange;
      cursor: pointer;
      font-size: 1.2rem;
    }
  }
}

.list-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  font-size: 1.8rem;
  color: $blue;
  font-weight: bold;
  margin-top: 2rem;
}

.data__item {
  font-size: 1.6rem;

  .item__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
}

.add__tracking__details {
  // display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 3rem;

  form {
    display: flex;

    @media only screen and (max-width: 868px) {
      flex-direction: column;
    }
  }

  input {
    width: 100%;
    height: 30px;
    border: 1px solid $orange;
    margin-right: 10px;
    margin-bottom: 1rem;
    font: inherit;
    padding: 18px 10px;
    font-size: 1rem;
  }

  button {
    height: 40px;
    border: 1px solid $orange;
    padding: 0 18px;
    font-size: 1rem;
    background-color: $orange;
    color: $white-bg;
    cursor: pointer;
    transition: all 0.3s;
    width: 100%;

    &:hover {
      background-color: $blue;
      color: white;
    }
  }
}