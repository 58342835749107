@import '../../sass/_variables.scss';
@import '../../sass/_mixin.scss';

.login__container {
  // background-color: $white-bg;
  padding: 2rem 1rem;
  border: 1px solid bisque;
  // box-shadow: 5px 6px 30px 0px rgba(0, 0, 0, 0.1);
  max-width: 700px;
  margin: auto;
  // text-align: center;
  font-size: 1rem;
  margin: 4rem auto;

  @media only screen and (max-width: 500px) {
    padding: 4rem 2rem;
  }

  .login-heading {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 2.4rem;
    font-weight: bold;
  }
}

.login__form {
  background-color: $white-bg;
  padding: 2rem 1rem;
  border: 1px solid bisque;
  box-shadow: 5px 6px 30px 0px rgba(0, 0, 0, 0.1);
  max-width: 700px;
  margin: auto;
  // text-align: center;
  font-size: 1.4rem;

  @media only screen and (max-width: 500px) {
    padding: 4rem 2rem;
  }


  &__group {
    margin-bottom: 1rem;

    label {
      display: block;
      margin-bottom: 0.5rem;
      font-weight: bold;
      font-size: 14px;
    }

    input {
      font-size: 14px;
      height: 50px;
      border: 1px solid $orange;
      padding: 0 20px;
      margin-bottom: 1rem;
      width: 100%;
    }

    button {
      height: 50px;
      border: 1px solid $orange;
      padding: 0 18px;
      font-size: 1.4rem;
      background-color: $orange;
      color: $white-bg;
      cursor: pointer;
      transition: all 0.3s;
      width: 100%;

      &:hover {
        background-color: $blue;
        color: white;
      }
    }
  }
}