@import "../sass/_variables.scss";
@import "../sass/_mixin.scss";


.id-container {
  .tracking-div {
    margin: 4rem auto;
    font-size: 1.4rem;

  }

  p {
    font-weight: 600;
  }

  .track-id-heading {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1rem;

    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }

  .tracking-info-extra {
    font-weight: bold;
  }

  span {
    display: block;
    font-weight: 400;
    margin: .2rem 0 .6rem 0;
  }

  .track-heading {
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
}

.delivery_details {
  margin-bottom: 2rem;
  font-size: 1.4rem;
  line-height: 1.8;
}

.custom-buttonn {
  /* height: 62px; */
  line-height: 50px;
  margin-bottom: 5rem;
  display: inline-block;
  background: #003064;
  color: white;
  font-size: 14px;
  font-weight: 600;
  padding: 0 35px;
  -webkit-transition: all .35s ease-in-out;
  -moz-transition: all .30s ease-in-out;
  -ms-transition: all .30s ease-in-out;
  -o-transition: all .30s ease-in-out;
  transition: all .30s ease-in-out;
  cursor: pointer;
}